import React from "react";

export default function Header() {
  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
            <img
              src="./images/vaishnavi-logofinal.svg"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
