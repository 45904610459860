import React from "react";
import Slider from "react-slick";

export default function Herosection() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div>
      <div className="herosection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 align-self-center text-center">
              <h1>Launching Soon..</h1>
              <p>
                <b>Vaishnavi Krishna Brindavan</b> by Vaishnavi Group, An
                upcoming upscale residences located near city's iconic locations
                <b> Banashankari & JP Nagar.</b> It provides 1800 to 2500 sqft
                areas of spacious <br /> <b>3 & 4 BHKs homes.</b> With features
                such as vastu, privacy & tallest building of South
                Bengaluru with 31 floors.
              </p>
              <button
                type="button"
                class="btn mt-2"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Enquire Now
              </button>

              <div
                class="modal fade text-left"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered  modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Contact form
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form
                        name="contact"
                        method="post"
                        data-netlify="true"
                        onSubmit="submit"
                        data-netlify-honeypot="bot-field"
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        <div class="row">
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Name"
                              name="name"
                            />
                          </div>
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Phone Number"
                              name="phonenumber"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Email"
                              name="email"
                            />
                          </div>
                          <div class="col">
                            <fieldset>
                              <div class="some-class">
                                <label>Need Brochure</label>{" "}
                                <br class="mobile-break"></br>
                                <input
                                  type="radio"
                                  class=" ml-0 ml-md-3 mr-1"
                                  name="brochure"
                                  value="Yes"
                                />
                                <label for="y">Yes</label>
                                <input
                                  type="radio"
                                  class="radio ml-3 mr-1"
                                  name="brochure"
                                  value="No"
                                />
                                <label for="no">No</label>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div class="col">
                            <input
                              type="text"
                              required
                              class="form-control"
                              placeholder="Message"
                              name="message"
                            />
                          </div>
                        </div>
                        {/* 
                        <div className="row">
                          <div className="col-12">
                            <div
                              style={{ fontSize: 12 }}
                              className="text-center"
                            >
                              *Phase - 1 Sold Out,{" "}
                              <b>Hurry up for Phase - 2 (NEW LAUNCH)</b>
                            </div>
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-12 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary mt-4"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <Slider {...settings}>
                <div>
                  <img className="img-fluid" src="./images/image1.jpg" alt="" />
                </div>
                <div>
                  <img className="img-fluid" src="./images/image2.jpg" alt="" />
                </div>
                <div>
                  <img className="img-fluid" src="./images/image3.jpg" alt="" />
                </div>
                <div>
                  <img className="img-fluid" src="./images/image4.jpg" alt="" />
                </div>
                <div>
                  <img className="img-fluid" src="./images/image5.jpg" alt="" />
                </div>
                <div>
                  <img className="img-fluid" src="./images/image6.jpg" alt="" />
                </div>
                <div>
                  <img className="img-fluid" src="./images/image7.jpg" alt="" />
                </div>
              </Slider>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="disclimer">
                Disclaimer The Project is being Developed by Vaishnavi Group .
                The Project is registered as “vaishnavi Krishna Brindavan" with
                RERA No: yet to be applied, available at
                website:http://rera.karnataka.gov.in. The information is
                presented as general information and no warranty is expressly or
                impliedly given that the completed development will comply in
                any degree with such artist’s impression or anticipated
                appearance.Recipients are advised to apprise themselves of the
                necessary and relevant information of the project(s)/offer(s)
                prior to making any purchase decisions. The Sale is subject to
                terms of Application Form and Agreement for Sale. All
                specifications of the unit shall be as per the final agreement
                between the Parties. *The prices mentioned are an indicative
                Agreement Value. Stamp Duty Registration, GST and Other Charges
                over and above the Agreement Value. The amenities mentioned here
                are a part of Vaishnavi Krishna Brindavan and proposed future
                development. This is an initiative by Smita an Individual
                Channel Partner RERA Regn No.
                PRM/KA/RERA/1251/310/AG/220521/002906 to provide information
                about Vaishnavi Krishna Brindavan is not to be construed as an
                official site of Vaishnavi Group. Distance mentioned is as per
                google map. Drive time refers to the time taken to travel by a
                car based on normal traffic conditions during non-peak hours as
                per Google maps may vary according to the current traffic
                conditions. Information provided is based on Online sources, the
                developer does not provide any guarantee on the same.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
